/**
 * 处理BwApi接口的参数
 */
export function formatBwApiParams (params) {
    const defaultParam = { //默认参数格式
        PARAMETER: '',
        SIGN: 'I',
        OPTION: 'EQ',
        LOW_FLAG:'0',
        LOW: '',
        HIGH: ''
    }
    let formatedParams = []
    params.map(item => {
        let formatItem = Object.assign(defaultParam,item)
        let ArrLOW = formatItem.LOW.split(',')
        let ArrHign = formatItem.HIGH.split(',')
        if(item.HIGH) formatItem.OPTION = 'BT';
        for(let i = 0,len=ArrLOW.length; i < len; i++) {
            for(let j = 0,len=ArrHign.length; j < len; j++) {
                formatedParams.push(Object.assign(JSON.parse(JSON.stringify(formatItem)),{
                    LOW:ArrLOW[i],
                    HIGH:ArrHign[j]
                }))
            }
        }
    })
    return formatedParams
}
/**
 * 判断字符串是否为JSON
 */
export function isJson(str) {
    if (typeof str == 'string') {
        try {
            var obj=JSON.parse(str);
            if(typeof obj == 'object' && obj ){
                return true;
            }else{
                return false;
            }
        } catch(e) {
            console.log('error：'+str+'!!!'+e);
            return false;
        }
    }
}