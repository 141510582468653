//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// const brand = 'ZBXN';
import { isJson } from '@/utils/util'
import male from "@/assets/customer/male.png";
import female from "@/assets/customer/female.png";
import filter from "@/assets/customer/filter.png";
export default {
  name: "Index",
  data() {
    return {
      mobile: "",
      memberInfo: {},
      memberList: [],
      filterPop: false,
      wxcpStepNames: [
        { label: "陌生阶段", value: "F01陌生阶段", selected: false },
        { label: "注册会员", value: "F02注册会员", selected: false },
        { label: "潜在购买会员", value: "F03潜在购买会员", selected: false },
        { label: "成交会员", value: "F04成交会员", selected: false },
      ],
      wxcpLevelNames: [
        { label: "一般", value: "一般", selected: false },
        { label: "重要", value: "重要", selected: false },
        { label: "核心", value: "核心", selected: false },
      ],
      loading: false,
      wxcpStepName: "",
      wxcpLevelName: "",
      original: [],
      male,
      female,
      filter,
    };
  },
  mounted() {
    window.document.title = "我的会员";
  },
  activated() {
    window.document.title = "我的会员";
  },
  created() {
    window.document.title = "我的会员";
    this.getMemberList();

  },
  methods: {
    searchMobile() {
      let searchMobile = this.mobile;
      let temp = [];
      const original = this.original;
      if (searchMobile && searchMobile.trim() != "") {
        original.forEach((item) => {
          if (item.remarkMobiles.toString().indexOf(searchMobile) > -1 || item.name && item.name.indexOf(searchMobile) > -1 ) {
              temp.push(item);
          }
        });
      } else {
        temp = JSON.parse(JSON.stringify(original));
      }
      this.memberList = temp;
      console.log("searchMobile", this.memberList);
    },
    getMemberList() {
      if (this.loading) return;
      this.loading = true;
      const that = this;
      this.memberList = [];
      this.axios
        .post(
          "/" + this.$ajaxPrefix.consumer + "/v1/wx/cp/externalcontact/list",
          {
            // this.axios.post('/v1/wx/cp/crm/store/getExclusiveCustomers', {
            pager: {
              pageIndex: 1,
              pageSize: 10000,
              rowsCount: 0,
              start: 0,
            },
            queryMap: {
              // "guideID": "",
              // "brand": brand,
              // wxcpStepName: this.wxcpStepName,
              // wxcpLevelName: this.wxcpLevelName,
              level: this.level,
              brand: "ZFHX",
              // "level": 'ZB24'
            },
          }
        )
        .then((res) => {
          if (res && res.data) {
            console.log("data", res.data);
            if (res.data.data && res.data.data.list.length > 0) {
              console.log(res.data);
              res.data.data.list.map(v =>{
                if(isJson(v.remarkMobiles)){
                  v.remarkMobiles = JSON.parse(v.remarkMobiles)
                }else{
                  v.remarkMobiles = []
                }
                if(isJson(v.tags)){
                  v.tags = JSON.parse(v.tags)
                }else{
                  v.tags = []
                }
              })
              that.original = res.data.data.list;
            } else {
            that.original = [];
            that.memberList = []
          }

            // if (Array.isArray(that.original)){
            //     that.original.forEach(item=>{
            //         if (item.lastConsumeDate != ''){
            //             item.lastConsumeDate = that.$moment(item.lastConsumeDate).format('yyyy-MM-DD');
            //         }
            //         if (item.mobile != ''){
            //             item.mobileTxt = item.mobile.slice(0, 4) + '****' + item.mobile.slice(-3);
            //         }else {
            //             item.mobileTxt = '';
            //         }
            //     });
            // }else {
            //     that.original = [];
            // }
            //  that.memberList = JSON.parse(JSON.stringify(that.original));
            that.searchMobile();
          } else {
            that.memberList = []
            that.original = [];
          }
          this.loading = false;
        });
    },
    toDetail(bpid, name) {
      if (bpid) {
        this.$router.push("/extcustomerPhoenix/list?bpid=" + bpid + "&name=" + name);
      } else {
          this.$toast({message: '非会员无法查看详情', duration: 5000});
      }
    },
    toggle(name, index) {
      if (name == "acitvities") {
        // this.acitvities.forEach(item=>{
        //     item.selected = false;
        // });
        // this[name][index]['selected'] = true;
        if (this[name][index].selected) {
          this[name][index].selected = !this[name][index].selected;
        } else {
          this.acitvities.forEach((item) => {
            item.selected = false;
          });
          this[name][index]["selected"] = true;
        }
      } else {
        this[name][index]["selected"] = !this[name][index]["selected"];
      }
    },
    sureFilter() {
      const that = this;
      let wxcpStepNames = this.wxcpStepNames;
      let wxcpStepNameTemp = [];//所选客户阶段
      wxcpStepNames.forEach((item) => {
        if (item.selected) {
          wxcpStepNameTemp.push(item.value);
        }
      });
      this.wxcpStepName = wxcpStepNameTemp.join(",");

      let wxcpLevelNames = this.wxcpLevelNames;
      let wxcpLevelNameTemp = [];//所选客户等级
      wxcpLevelNames.forEach((item) => {
        if (item.selected) {
          wxcpLevelNameTemp.push(item.value);
        }
      });
      this.wxcpLevelName = wxcpLevelNameTemp.join(",");
      let tempList = JSON.parse(JSON.stringify(this.original));
      this.memberList = tempList.filter(item =>{
        let wxcpStepNameTag = '';
        let wxcpLevelNameTag = '';
        item.tags && item.tags.map(tag =>{
          if(tag.groupName === "客户阶段"){
            wxcpStepNameTag = tag.tagName
          }
          if(tag.groupName === "客户等级"){
            wxcpLevelNameTag = tag.tagName
          }
        })
        console.log(wxcpStepNameTag,wxcpLevelNameTag);
        console.log(wxcpStepNameTemp,wxcpLevelNameTemp);
        //筛选全不选展示全部，否则选择筛选包含筛选条件的项
        return (wxcpStepNameTemp.length === 0 || wxcpStepNameTemp.indexOf(wxcpStepNameTag) > -1 ) && (wxcpLevelNameTemp.length === 0 ||  wxcpLevelNameTemp.indexOf(wxcpLevelNameTag) > -1 )
      })
      this.filterPop = false;
    },
  },
};
